#homepage {
    .search-box {
        input {
            background-color: #FFF !important;
        }

        .SumoSelect.open > .optWrapper > .options li.opt {
            background: #FFF;
        }
    }
}

.form-control {
    background-color: transparent !important;
    border-color: #262626 !important;
}

body {
    .range-col {
        &.active {
            > p {
                background: #080808;
            }
        }

        .range-wrapper {
            background: #080808;
        }
    }
}