$colors-section: (
    'section-primary': color('primary'),
    'section-secondary': color('primary'),
);

.section {
    padding-top: 80px;
    padding-bottom: 80px;

    @media (max-width: 690px) {
        padding-top: 40px !important;
    }

    .section p a:hover,
    .section .section-text a:hover {
        font-weight: 700;
    }

    hr.section-separator-home {
        border: none;
        height: 1px;
        background: color('section-primary', '', '', $colors-section);
        color: color('section-secondary', '', '', $colors-section);
        width: 100px;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    hr.section-separator {
        border: none;
        height: 3px;
        background: color('section-secondary', '', '', $colors-section);
        color: color('section-secondary', '', '', $colors-section);
        width: 100px;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        float: left;
    }

    .section-separator-carousel {
        border: none;
        height: 3px;
        background: color('section-secondary', '', '', $colors-section);
        color: color('primary');
        width: 100px;
        position: relative;
        margin-top: 10px;
        margin-bottom: 0;
        float: left;
    }

    h1,
    .section-title {
        font-family: font(main);
        font-size: 25px;
        font-weight: 500;
        color: color('text', 'light');
    }

    h2 {
        font-family: font(main);
        font-size: 18px;
        font-weight: 500;
        color: color('text', 'light');
    }

    .section-subtitle {
        font-family: font(main);
        font-weight: 300;
        font-size: 28px;
        color: color('text', 'light');
        text-align: center;
        font-style: italic;
    }

    p,
    .section-text {
        font-family: font(sub);
        font-weight: 300;
        font-size: 15px;
        line-height: 1.4;
        color: color('text', 'light');
        margin-top: 20px;
        margin-bottom: 20px;
        @include page-text();
    }

    .section-btn {
        margin: 0 auto;
        color: color('white') !important;
    }
}

.property-section {
    padding-top: 40px;
    padding-bottom: 40px;
}

#property-group-view {
    .section {
        padding-top: 20px !important;
    }
}
