.banners-wrapper {

    .banner-item {
        position: relative;

        @media(max-width: $media_md) {
            img {
                display: block;
                margin: 0 auto 15px;
                max-width: 100%;
            }
        }

        .banner-link {
            &:hover {
                text-decoration: none;
            }
        }

        .banner-content {
            width: 100%;
            position: relative;

            .banner-title {
                font-weight: 500;
                font-family: font(main);
                font-size: 20px;
                color: color('white');
                text-transform: uppercase;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                span {
                    font-size: 33px;
                    display: block;
                    border-top: 1px solid rgba(255, 255, 255, 0.5);
                    line-height: 1;
                    margin-top: 10px;
                    padding-top: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                }

                &.v2 {
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    transform: translateY(-50%);
                    padding: 10px;
                    left: 0;
                    right: 0;
                    width: auto;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 230px;
                }
            }
        }
    }

}

.group-view-section {
    h1 {
        text-align: center;
        font-size: 30px !important;
        font-weight: 300 !important;
        color: #555;
        text-transform: uppercase !important;
    }

    h2,
    h3 {
        text-align: center;
        font-weight: 300 !important;
        font-size: 30px !important;
        color: #555;
        font-style: italic;
    }

    .container-fluid {
        .banners_wrapper {
            img {
                width: 100%;
            }

            .banner-content {
                .banner-title {
                    font-size: 22px;
                }
            }
        }
    }
}